class AnimCircle {
  constructor() {
    this.initCircle()
  }

  inViewPort(el) {
    let top = el.offsetTop
    let height = el.offsetHeight

    while (el.offsetParent) {
      el = el.offsetParent
      top += el.offsetTop
    }
    return (
      (top + (height / 2)) <= (window.pageYOffset + window.innerHeight)
    )
  }

  initCircle() {
    function positionTheDot() {

      const parentElement = document.getElementById("bridgeSection")
      const top = parentElement.offsetTop
      const height = parentElement.offsetHeight

      // What percentage down the page are we?
      const totalPerc = document.documentElement.scrollHeight - document.documentElement.clientHeight
      const currentScroll = document.documentElement.scrollTop + document.body.scrollTop
      const scrollPercentage = currentScroll / totalPerc

      const adjust = height * 0.2
      const newPerc = currentScroll / (top - adjust)  // + sidebar height?

      // Get path1 length
      const path1 = document.getElementById("theMotionPath1")
      const path1Len = path1.getTotalLength()

      // Get the position of a point at <scrollPercentage> along the path1.
      const pt1 = path1.getPointAtLength(newPerc * path1Len)

      // Get path1 length
      const path2 = document.getElementById("theMotionPath2")
      const path2Len = path2.getTotalLength()

      // Get the position of a point at <newPerc> along the path2.
      const pt2 = path2.getPointAtLength(newPerc * path2Len)

      // Position the red dot at this point
      const dot1 = document.getElementById("dot1")
      dot1.setAttribute("transform", "translate("+ pt1.x + "," + pt1.y + ")")

      // Position the red dot at this point
      const dot2 = document.getElementById("dot2")
      dot2.setAttribute("transform", "translate("+ pt2.x + "," + pt2.y + ")")



      // ADD TEXTS
      const firstText = document.getElementById('firstText')
      const mainText = document.getElementById('mainText')
      const secTextA = document.getElementById('secTextA')
      const secTextB = document.getElementById('secTextB')


      if (newPerc > 0.30) {
        firstText.classList.add('APPEAR')
      } else {
        firstText.classList.remove('APPEAR')
      }

      if (newPerc > 0.78) {
        secTextA.classList.add('APPEAR')
        secTextB.classList.add('APPEAR')
      } else {
        secTextA.classList.remove('APPEAR')
        secTextB.classList.remove('APPEAR')
      }

      if (newPerc > 0.99) {
        mainText.classList.add('APPEAR')
      } else {
        mainText.classList.remove('APPEAR')
      }
    }

    // Update dot position when we get a scroll event.
    window.addEventListener("scroll", positionTheDot)

    // Set the initial position of the dot.
    positionTheDot()
  }
}

export default AnimCircle
