import Swiper, { Autoplay } from 'swiper'
const Rellax = require('rellax')

class SliderPar {
  constructor() {
    this.initSlider()
  }

  initSlider() {

    const rellax = new Rellax('.jumbotron', {
      speed: -4
    })

    Swiper.use([Autoplay]);

    const numbOfSlides = document.getElementsByClassName('swiper-slide').length

    let auto = {
      delay: 6000,
      disableOnInteraction: false,
    }
    if (numbOfSlides == 1) {
      auto = false
    } else if (numbOfSlides <= 0) {
      auto = false
      console.log('PLEASE ADD SOME FEATURED CLIENTS!');
    }

    const mySwyper = new Swiper('.swiper-container', {
      loop: auto,
      speed: 1200,
      autoplay: auto,
    })



    // mySwyper.autoplay.start()
    mySwyper.autoplay.running

    // // pause on SCROLL
    // function scrollingFunction() {
    //   if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
    //     // slider.pause()
    //   } else {
    //     // slider.resume()
    //   }
    // }
    //
    // window.addEventListener('scroll', scrollingFunction)
  }
}

export default SliderPar
