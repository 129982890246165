import primary from './primary'
import AnimCursor from './animCursor'
import Swip from './swip'
import SliderPar from './slider'
import AnimCircle from './animCircle'

const animCursor = new AnimCursor()

if ( document.querySelector('.jumbotron')) {
  const slider = new SliderPar()
}

if ( document.querySelector('.bridge_image')) {
  const animCircle = new AnimCircle()
}

if ( document.querySelector('.work')) {
  const swiper1 = new Swip()
}
