import Swiper from 'swiper'
// import Swiper styles
// import './swiper-bundle.css'


class Swip {
  constructor() {
    this.init()
  }

  init() {
    const catAnc = document.getElementById('cats_a')
    const allAnc = document.getElementById('all_a')
    const catH3 = document.getElementById('cats_a_h3')
    const allH3 = document.getElementById('all_a_h3')
    const fromCategory = document.getElementById('fromCategory')
    const blueLine = document.getElementById('blue_work_line')

    const initSlide = (fromCategory) ? 1 : 0

    const mySwiper = new Swiper('.swiper-container', {
      autoHeight: true,
      loop: false,
      speed: 800,
      initialSlide: initSlide,
      simulateTouch: false
    })

    catAnc.addEventListener('click', () => {
      mySwiper.slideNext()
      catH3.classList.add('work_active')
      allH3.classList.remove('work_active')
      blueLine.classList.add('moveRight')
    })

    allAnc.addEventListener('click', () => {
      mySwiper.slidePrev()
      catH3.classList.remove('work_active')
      allH3.classList.add('work_active')
      blueLine.classList.remove('moveRight')
    })
  }
}

export default Swip
