const cards = document.querySelectorAll('div.clients__CARD')

// When half of the element is in view
function inViewPort(el) {
  let top = el.offsetTop
  let height = el.offsetHeight

  while (el.offsetParent) {
    el = el.offsetParent
    top += el.offsetTop
  }
  return (
    (top + (height / 2)) <= (window.pageYOffset + window.innerHeight)
  )
}

cards.forEach(el => {
  if (inViewPort(el)) {
    // el.classList.add('come-in')
    el.classList.add('already-visible')
  }
})

// SCROLL EFFECT on MENUBAR
function scrollFunction() {
  if (window.matchMedia('(min-width: 1056px)').matches) {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      document.getElementsByClassName('main-header')[0].style.height = '7.4rem'
      document.getElementsByClassName('main-header__cont')[0].style.padding = '0.8rem 1rem'
      document.getElementsByClassName('main-header__cont')[0].style.transition = 'padding 0.25s ease-in-out'
    } else {
      document.getElementsByClassName('main-header')[0].style.height = '11.8rem'
      document.getElementsByClassName('main-header__cont')[0].style.padding = '2.6rem 1rem'
      document.getElementsByClassName('main-header__cont')[0].style.transition = 'padding 0.35s ease-in-out'
    }
  } else {
    document.getElementsByClassName('main-header')[0].style.height = '7.4rem'
    document.getElementsByClassName('main-header__cont')[0].style.padding = '0 1rem'
  }

  cards.forEach(el => {
    if (inViewPort(el)) {
      el.classList.add('come-in')
    }
  })
}

window.addEventListener('scroll', scrollFunction)
window.addEventListener('resize', scrollFunction)


// RANDOM COLOR FOR SUBBRAND
const surpriseLogo = document.querySelector('.main-header__subBrand')
const surpriseLogoSVG = document.getElementById('subBrand_Group_28')

function getRandomColor() {
  const colors = [
    '#1370F4', '#00DA56', '#F70052', '#FF5400', '#FFB612', '#039AE4',
    '#00EBE3', '#D775DE', '#FBEC00', '#FF58AA', '#D66943', '#FF0000',
    '#B810A8', '#3D9F00', '#CDD0CA', '#FF7E4F', '#45BEB9', '#FF89BC',
    '#1314D0', '#F7F4F4']

  return colors[Math.floor(Math.random() * colors.length)]
}

surpriseLogo.addEventListener('click', () => {
  surpriseLogoSVG.style.fill = getRandomColor()
})

// // SIDEBAR
// burger
const burger = document.querySelector('.target-burger')
const sidebar = document.getElementById('sidebar12')
burger.addEventListener('click', (e) => {
  e.preventDefault()

  if (sidebar.classList.contains('sidebarAPPEAR')) {
    burger.classList.remove('toggled')
    sidebar.classList.add('sidebarDISAPPEAR')
    setTimeout(() => {
      sidebar.classList.remove('sidebarAPPEAR')
      sidebar.classList.remove('sidebarDISAPPEAR')
    }, 800)
  } else {
    burger.classList.add('toggled')
    sidebar.classList.add('sidebarAPPEAR')
  }
})
